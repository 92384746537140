<template>
  <div>
    <LiefengModal
        title="修改角色"
        :value="controlForm.formFlag"
        :fullscreen="false"
        width="500px"
        height="380px"
        @input="closeModal"
    >
      <template v-slot:contentarea>
        <Form
            :model="formData"
            :label-colon="true"
            :label-width="100"
            :rules="formDataValidate"
            ref="form"
        >

          <FormItem label="角色名称" prop="roleName">
            <Input style="width:300px" v-model.trim="formData.roleName" :maxlength="50"
                   placeholder="请填写角色名称"></Input>
          </FormItem>
          <FormItem label="角色编码" prop="roleCode">
            <Input style="width:300px" v-model.trim="formData.roleCode" :maxlength="50"
                   placeholder="请填写角色编码"></Input>
          </FormItem>
          <!-- <FormItem label="启用状态" prop="status">
            <i-switch true-value="1" false-value="2" v-model="formData.status"/>
          </FormItem> -->
          <FormItem label="角色类型" prop="roleType">
            <Select v-model="formData.roleType" style="width:200px;" placeholder="角色类型">
              <Option value="1">全局角色</Option>
              <Option value="2">租户/平台角色</Option>
              <Option value="3">组织机构内角色</Option>
            </Select>
          </FormItem>
          <!-- <FormItem label="关联菜单" prop="roleMenu">
            <Tree ref="tree" :data="childrenList" show-checkbox></Tree>
          </FormItem> -->
          <FormItem label="备注">
            <Input type="textarea" style="width:300px" :rows="4" v-model.trim="formData.remark" ></Input>
          </FormItem>

        </Form>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";

export default {
  components: {LiefengModal},
  data() {
    return {
      // childrenList: [],
      controlForm: {
        formFlag: false,
        timer:'',
      },
      formData: {
        roleName: '',
        roleCode: '',
        // status: '1',
        roleType: '',
        remark:''
      },
      formDataValidate: {
        roleName: [
          {required: true, message: '角色名称不能为空', trigger: 'blur'}
        ],
        roleCode: [
          {required: true, message: '角色编码不能为空', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {

    getDetail(roleId) {
      this.$get("/auth/api/auth/pc/role/queryById", {
        roleId
      }).then(res => {
        if (res.code == 200) {
          this.formData['roleId'] = res.data.roleId
          this.formData.roleName = res.data.roleName
          this.formData.roleCode = res.data.roleCode
          // this.formData.status = res.data.status
          this.formData.roleType = res.data.roleType
          this.formData.remark = res.data.remark
          // this.getChildrenList();
        } else {
          this.$Message.error({
            content:'获取角色详情失败',
            background:true
          })
          return
        }
      });
    },

    //递归改children
    dg(children){
      for (var i = 0; i < children.length; i++) {
        children[i]['title'] = children[i].name;
        children[i]['value'] = children[i].sysModuleId;
        //选中菜单
        if (this.formData.roleMenu && this.formData.roleMenu.length) {
          for (var j = 0; j < this.formData.roleMenu.length; j++) {
            if (children[i].value == this.formData.roleMenu[j]) {
              children[i]['checked'] = true;
              break;
            }
          }
        }
        if (children[i].child) {
          children[i]['children'] = children[i].child
          this.dg(children[i].children);
        }
      }
    },

    // 获取全部子级
    // getChildrenList(){
    //   this.$get("/auth/api/auth/pc/systemModule/selectAllMenuTree", {
    //   }).then(res => {
    //     if (res.code == 200) {
    //       this.childrenList = res.dataList;
    //       //处理成label，value
    //       this.dg(this.childrenList);
    //     }
    //   });
    // },
    saveData() {
      this.$refs.form.validate((valid)=> {
            if (valid) {
              // this.formData.roleMenu = []
              // var arr = this.$refs.tree.getCheckedAndIndeterminateNodes();
              // for (var i = 0; i < arr.length; i++) {
              //     this.formData.roleMenu.push(arr[i].value)
              // }


              this.$post('/auth/api/auth/pc/role/update',{
                ...this.formData,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
              }).then(res=>{
                if(res.code == 200){
                  //刷新父功能
                  this.$Message.success({
                    content:'修改成功',
                    background:true
                  });
                  //父页面刷新列表
                  this.closeModal();
                  this.$emit('resetBtn')
                }else{
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                  return
                }
              })
            }
          }
      );
    },
    //递归清空树选中
    dgClear(children){
      for (var i = 0; i < children.length; i++) {
        children[i]['checked'] = false;
        if (children[i].children) {
          this.dgClear(children[i].children);
        }
      }
    },
    closeModal(flag){
      //关闭弹窗(打开也会调用此方法)
      if (flag) {
        return
      }
      //清空表单
      Object.assign(this.$data.formData, this.$options.data().formData);
      //清空树
      // this.dgClear(this.childrenList);
      //重置表单字段Form
      this.$refs['form'].resetFields();
      this.controlForm.formFlag = flag;

    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

</style>